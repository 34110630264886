import { createApp } from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import 'bootstrap/dist/css/bootstrap.css'
import { library } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { faFacebookSquare, faInstagramSquare } from '@fortawesome/free-brands-svg-icons'

library.add(faFacebookSquare, faInstagramSquare)

createApp(App)
  .component('FAIcon', FontAwesomeIcon)
  .mount('#app')
