<template>
  <header class="header">
    <OpeningHours />
    <Navigation />
  </header>
</template>

<script>
import Navigation from './Navigation.vue'
import OpeningHours from './Hours.vue'

export default {
  components: {
    Navigation,
    OpeningHours,
  },
}
</script>

<style lang="scss">
.header {
  display: flex;
  flex-direction: column;
  @include mobile() {
    flex-direction: column-reverse;
  }
}
</style>
