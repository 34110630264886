<template>
  <div class="hours" timescope itemtype="https://schema.org/LocalBusiness">
    <div class="hours__container container-md">
      <div class="hours__wrapper">
        <span class="hours__label">營業時間:</span>
        <div class="hours__days">
          <time class="hours__item" itemprop="openingHours" datetime="Mo-Fr 11:00-19:00">星&#8288;期&#8288;一&#8288;至&#8288;五 11am&nbsp;-&nbsp;7pm</time>
          <time class="hours__item" itemprop="openingHours" datetime="Sa 11:00-18:00">星&#8288;期&#8288;六 11am&nbsp;-&nbsp;6pm</time>
          <time class="hours__item hours__item--last" itemprop="openingHours" datetime="Su, PH">星&#8288;期&#8288;日&#8288;&amp;&#8288;公&#8288;眾&#8288;假&#8288;期 &nbsp; 休&#8288;息</time>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss">
.hours {
  background: #000;
  color: #fff;
  text-align: center;
  font-size: 0.8rem;
  @include mobile() {
    font-size: 0.66rem;
  }

  &__container {
    display: flex;
    @include padding-y($gutter * 0.2);
  }

  &__wrapper {
    display: flex;
    margin: auto;
    gap: $gutter;

    @include mobile() {
      flex-direction: column;
      gap: 0;
    }
  }

  &__days {
    display: flex;
    gap: $gutter * 2;
    @include mobile() {
      gap: $gutter * 0.75;
    }
  }

  &__item {
    position: relative;

    &::after {
      content: '|';
      position: absolute;
      transform: translate(50%, -50%);
      top: 50%;
      right: $gutter * -1;
      @include mobile() {
        right: $gutter * -0.375;
      }
    }

    &--last::after {
      content: none;
    }
  }
}
</style>
