<template>
  <Banner />
  <Occupations />
</template>

<script>
import Banner from './Banner.vue'
import Occupations from './Occupations.vue'

export default {
  components: {
    Banner,
    Occupations,
  },
}
</script>

<style lang="scss">
.section {
  @include padding-y($gutter);
  &__heading {
    text-decoration: underline;
  }
}
</style>
