<template>
  <div class="categories">
    <ul class="categories__wrapper" ref="wrapper">
      <li class="categories__item" v-for="(item, index) in list" :key="index">
        <img class="categories__image" src="http://via.placeholder.com/300x200" @mousedown.prevent="dragStart" />
        <span class="categories__label fs-2">分類</span>
        <a class="categories__link" href="#">立即選擇</a>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  props: {
    list: Array,
  },
  data () {
    return {
      scrollPos: 0,
      mousePos: 0,
      mouseDown: false,
    }
  },
  methods: {
    dragStart (event) {
      console.log(this.$refs)
      this.scrollPos = this.$refs.wrapper.scrollLeft
      this.mousePos = event.clientX
      this.mouseDown = true
      window.addEventListener('mousemove', this.scroll)
      window.addEventListener('mouseup', this.dragEnd)
    },
    scroll (event) {
      if (this.mouseDown) {
        this.$refs.wrapper.scrollLeft -= event.clientX - this.mousePos
        this.mousePos = event.clientX
      }
    },
    dragEnd (event) {
      this.mouseDown = false
      window.removeEventListener('mousemove', this.scroll)
      window.removeEventListener('mouseup', this.dragEnd)
    },
  },
}
</script>

<style lang="scss">
.categories {
  height: 270px;

  &__wrapper {
    display: flex;
    gap: $gutter;
    overflow: scroll;
    max-width: 100vw;
    position: absolute;
    left: 0;
    @include padding-x($margin-xs);
    @media only screen and (min-width: $breakpoint-sm) {
      @include padding-x($margin-sm);
    }
    @media only screen and (min-width: $breakpoint-md) {
      @include padding-x($margin-md);
    }
    @media only screen and (min-width: $breakpoint-lg) {
      @include padding-x($margin-lg);
    }
    @media only screen and (min-width: $breakpoint-xl) {
      @include padding-x($margin-xl);
    }
    @media only screen and (min-width: $breakpoint-xxl) {
      @include padding-x($margin-xxl);
    }
  }

  &__item {
    display: flex;
    flex-direction: column;
  }

  &__image {
    cursor: grab;
  }

  &__link {
    color: black;
    @include hover-and-active() {
      color: black;
    }
  }
}
</style>
