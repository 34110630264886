<template>
  <div class="banner container-md">
    <a href="#" class="banner__link">
      <img class="banner__image" src="http://via.placeholder.com/1320x740" />
    </a>
  </div>
</template>

<style lang="scss">
.banner {
  display: flex;
  flex-basis: 20%;
  @include padding-y($gutter);
  @include mobile() {
    @include padding-y($gutter * 0.5);
  }

  &__link {
    overflow: hidden;
    @include hover-and-active() {
      .banner__image {
        filter: brightness(90%);
        transform: scale(1.2);
        transition: 0.5s;
      }
    }
  }

  &__image {
    width: 100%;
    height: auto;
  }
}
</style>
