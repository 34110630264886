<template>
  <Header />
  <Body />
</template>

<script>
import Header from './components/Header.vue'
import Body from './components/Body.vue'

export default {
  name: 'App',
  components: {
    Body,
    Header,
  },
}
</script>

<style lang="scss">
// html, body {
//   margin: 0;
//   padding: 0;
// }

// a {
//   text-decoration: none;
//   color: inherit;
// }

html {
  ol, ul {
    margin: 0;
    padding: 0;
  }

  li {
    list-style-type: none;
  }

  @include mobile() {
    .container-md {
      @include padding-x(0);
    }
  }
}

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  // color: #2c3e50;
  text-underline-offset: 0.25em;
}
</style>
