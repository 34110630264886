<template>
  <section id="occupations" class="occupations section container">
    <h1 class="section__heading fs-1">各行各業</h1>
    <Categories :list="occupations" />
  </section>
</template>

<script>
import Categories from './Categories.vue'

export default {
  components: {
    Categories,
  },
  data () {
    return {
      occupations: Array(6).fill({}),
    }
  },
}
</script>
