<template>
  <nav class="navigation">
    <div class="navigation__container container-md">
      <a class="navigation__home" href="/"><img class="navigation__logo" src="../assets/logo-placeholder.png" /></a>
      <ul class="navigation__main">
        <li class="navigation__item"><a class="navigation__link" href="/">護士鞋</a></li>
        <li class="navigation__item"><a class="navigation__link" href="/">空姐鞋</a></li>
        <li class="navigation__item"><a class="navigation__link" href="/">其他</a></li>
        <li class="navigation__item"><a class="navigation__link" href="/">合作客戶</a></li>
      </ul>
      <ul class="navigation__sub">
        <!-- <span>Contact</span> -->
        <li class="navigation__item">
          <a class="navigation__link" href="https://www.facebook.com/shoesway.sukwai/?hc_ref=PAGES_TIMELINE"
          ><FAIcon class="navigation__icon" :icon="['fab', 'fa-facebook-square']" /></a>
        </li>
        <li class="navigation__item">
          <a class="navigation__link" href="http://www.imgrum.net/user/shoesway.sukwai/3170209000"
          ><FAIcon class="navigation__icon" :icon="['fab', 'fa-instagram-square']" /></a>
        </li>
      </ul>
    </div>
  </nav>
</template>

<style lang="scss">
.navigation {
  border-bottom: 2px solid #000;

  &__container {
    display: flex;
    gap: 5px;
    @include mobile() {
      flex-direction: column;
    }
  }

  &__home {
    display: flex;
  }

  &__logo {
    height: 60px;
    margin: auto;
  }

  &__main,
  &__sub {
    display: flex;
    margin-bottom: 0;
  }

  &__main {
    @include mobile() {
      background: whitesmoke;
      justify-content: center;
    }
  }

  &__sub {
    margin-left: auto;

    @include mobile() {
      display: none;
    }
  }

  &__link {
    text-decoration: none;
    display: inline-flex;
    height: 100%;
    align-items: flex-end;

    padding: 0 10px 10px;
    @include mobile() {
      padding: 10px;
    }

    color: #000;
    @include hover-and-active() {
      background: #000;
      color: #fff;
      transition: 0.5s;
    }
  }

  &__icon {
    font-size: 2em;
  }
}
</style>
